import { Component, Show } from 'solid-js'
import { FormErrorProps } from '~/components/form-error/form-error.interfaces'
import styles from '~/components/form-error/form-error.module.scss'

const FormError: Component<FormErrorProps> = ({ response }) => {
  return (
    <Show when={response.status === 'error'}>
      <div class={styles.formError}>
        {response.message}
      </div>
    </Show>
  )
}

export default FormError
